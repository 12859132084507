<template>
  <v-container>
    <v-row justify="center" class="mt-3">
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">Image Item</h4>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xl="8">
        <v-card>
          <div class="grey--text text-center py-15" v-if="loading">
            <v-progress-circular indeterminate size="50" />
            <div class="caption my-2">Loading request...</div>
          </div>
          <v-card-text v-else>
            <v-simple-table v-if="currentMatchRequest" class="text-right">
              <template v-slot:default>
                <tbody>
                  <tr v-if="currentMatchRequest.requestUserId && canShowUserInfo()">
                    <td class="text-right">User ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'user.view',
                          params: { id: currentMatchRequest.requestUserId },
                        }"
                      >
                        {{ currentMatchRequest.requestUserName }}
                      </v-btn>
                    </td>
                  </tr>

                  <tr v-for="item in items" :key="item.name">
                    <td width="200px">{{ item.name }}</td>
                    <td class="text-left">
                      <div style="whitespace: pre-wrap; max-width: 500px">
                        {{ emptyChecker(item.value(currentMatchRequest)) }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Image</td>
                    <td class="py-2 text-left">
                      <media-image
                        :mime="currentMatchRequest.mimeType"
                        :data="currentMatchRequest.imageBase64"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { FACE_REQUEST_IMAGE_SINGLE } from "@/constants/api";
import MediaImage from "@/views/components/Media.vue";

export default {
  name: "MatchRequest",
  metaInfo: { title: "Request Image" },
  components: { MediaImage },
  data() {
    return {
      loading: false,
      currentMatchRequest: null,
      items: [
        { name: "Type", value: (item) => item.type },
        { name: "MD5Sum", value: (item) => item.md5sum },
        { name: "Content Type", value: (item) => item.mimeType },
        { name: "File Name", value: (item) => item.fileName },
        {
          name: "Requested At",
          value: (item) => moment(item.createdAt).format("DD MMM, YYYY \n hh:mm:ss A"),
        },
      ],
    };
  },
  mounted() {
    if (this.$route.params.md5sum) {
      this.loading = true;
      this.fetchMatchRequest(this.$route.params.md5sum).finally(() => (this.loading = false));
    }
  },
  methods: {
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
    fetchMatchRequest(md5sum) {
      return this.$axios
        .get(`${FACE_REQUEST_IMAGE_SINGLE}?md5sum=${md5sum}`)
        .then((response) => {
          if (response.data.code === 0) {
            this.currentMatchRequest = response.data.data;
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          this.$router.back();
        });
    },
  },
};
</script>

<style scoped></style>
